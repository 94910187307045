.loginPage__container {
    margin: 20px;
    padding: 30px;
    background-color: white;
}

.loginPage__signInTitle {
    display: block;
    max-width: 620px;
    font-size: 36px;
    line-height: 46px;
    font-weight: 600;
    color: #000082;
    padding-bottom: 18px;
    margin: 20px 0 34px;
    border-bottom: 1px solid #767676;
}

#login_page::part(form_submit_login) {
    max-width: 378px;
}

#login_page::part(email_address_label),
#login_page::part(account_password_label) {
    display: block;
    font-size: 16px;
    line-height: 26px;
    max-width: 100%;
    margin-bottom: 2px;
    color: #333;
}

#login_page::part(email_address),
#login_page::part(account_password) {
    padding: 8px 8px;
    border: 1px solid #767676;
    border-radius: 4px;
    transition: box-shadow ease-in-out 0.2s;
    box-sizing: border-box;
    cursor: text;
    display: block;
    font-size: 16px;
    font-stretch: 100%;
    font-weight: 400;
    height: 39px;
    line-height: 21px;
    outline: none;
    width: 100%;
}

#login_page::part(email_address):focus,
#login_page::part(account_password):focus {
    border: 1px solid #8DAFF9;
    box-shadow: 0 0 3px 1px #8DAFF9;
}

#login_page::part(email_address)::placeholder,
#login_page::part(account_password)::placeholder {
    opacity: 0;
}

#login_page::part(submit_login_email),
#login_page::part(submit_login_password) {
    margin-bottom: 40px;
}

#login_page::part(submit_button) {
    color: #ffffff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 0;
    text-align: center;
    white-space: nowrap;
    padding: 0 32px;
    border-radius: 5px;
    background-color: #0064DD;
    margin: 0;
    height: 49px;
    display: block;
    border: none;
    outline: none;
}

#login_page::part(submit_button):hover,
#login_page::part(submit_button):focus {
    background-color: #0055BC;
}

#login_page::part(error_message),
#login_page::part(alert) {
    color: #C91528;
    margin: 20px 0;
}

#login_page::part(forgot_password) {
    font-size: 14px;
    margin-top: 37px;
}

#login_page::part(forgot_password_link),
#login_page::part(recaptcha_disclaimer_link) {
    color: #0064DD;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    outline: none;
    white-space: nowrap;
}

#login_page::part(forgot_password_link):hover,
#login_page::part(recaptcha_disclaimer_link):hover,
#login_page::part(forgot_password_link):focus,
#login_page::part(recaptcha_disclaimer_link):focus {
    text-decoration: underline;
}
